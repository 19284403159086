
import api from "@/api";
import { position, GeolocationPositionError } from "@/types/inerface";
import LocationSearch from "@/components/LocationSearch.vue";
import BusList from './BusList.vue'
import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import { Toast } from "vant";
import { AxiosRequestConfig } from "axios";
import { useStore } from "vuex";
import { stringifyQuery, useRoute } from 'vue-router';
import list from '@/utils/cityList'

export default defineComponent({
  components: {
    BusList,
    LocationSearch,
  },
  setup() {
    const store = useStore();
    const route = useRoute()
    const currentCity = route.query
    // console.log(currentCity)
    const location = computed(() => store.state.city.city)
    const cityid = computed(() => store.state.city.id)
    // let histroyBusList = localStorage.busList === undefined ? [] :JSON.parse(localStorage.busList).reverse()
    let state = reactive({
      location_lon: "",
      location_lat: "",
      stationInfo: {},
      busList: [],
      histroyBusList: localStorage.busList === undefined ? [] :JSON.parse(localStorage.busList).reverse()
    });
    const handleShowPosition = (position: position) => {
      let latitude = position.coords.latitude;
      let longitude = position.coords.longitude;
      // 缓存定位
      store.commit('coordinate', {
        latitude,
        longitude
      })
      if (Object.prototype.hasOwnProperty.call(currentCity, 'id')) {
        const urlCity = list.find((item) => {
          return item.id === currentCity.id
        })
        store.commit('currentCity', {
          ...urlCity
        })
      } else {
        api.bus.locationToCity({
          lat: latitude,
          lng: longitude
        }).then((res: AxiosRequestConfig["data"]) => {
          if (res?.code === 0) {
            store.commit('currentCity', {
              city: res.data.city,
              id: res.data.id
            })
          }
        })
      }
    };
    const handleOnError = (error: GeolocationPositionError) => {
      console.log(error);
      switch (error.code) {
        case error.PERMISSION_DENIED:
          Toast.fail("您拒绝对获取地理位置的请求");
          break;
        case error.POSITION_UNAVAILABLE:
          Toast.fail("位置信息是不可用的");
          break;
        case error.TIMEOUT:
          Toast.fail("请求您的地理位置超时");
          break;
      }
    };
    const handleLocationInfo = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          handleShowPosition,
          handleOnError,
          {
            // 指示浏览器获取高精度的位置，默认为false
            enableHighAccuracy: true,
            // 指定获取地理位置的超时时间，默认不限时，单位为毫秒
            // timeout: 5000,
            // 最长有效期，在重复获取地理位置时，此参数指定多久再次获取位置。
            // maximumAge: 3000,
          }
        );
      } else {
        Toast.fail("浏览器或手机不支持位置信息");
      }
    };
    const handleSearch = async (val: string) => {
      if (cityid.value === '') {
        Toast.fail({
          duration: 800,
          message: '请先选择城市，谢谢',
          forbidClick: true,
        });
        return;
      }
      if (val.length < 2) {
        Toast.fail({
          duration: 800,
          message: '请输入2个关键字进行搜索，谢谢',
          forbidClick: true,
        });
        return;
      }
      Toast.loading({
        duration: 300,
        message: '加载中...',
        forbidClick: true,
      });
      // console.log(store.state)
      api.bus.roadLines({
        cityid: cityid.value,
        keywords: val
      }).then((res: AxiosRequestConfig["data"]) => {
        if (res?.code === 0) {
          if (res.data.length === 0) {
            Toast.fail({
              duration: 800,
              message: '暂无结果，请输入其它关键字进行搜索，谢谢',
              forbidClick: true,
            });
          } else {
            state.busList = res.data
          }
        }
      })
    }

    onMounted(() => {
      if (cityid.value === '') {
        // handleLocationInfo();
      }
    });

    const clearHistory = () => {
      Toast.loading({
        duration: 500,
        message: '加载中...',
        forbidClick: true,
      });
      state.histroyBusList = []
      localStorage.clear()
    }

    return {
      clearHistory,
      handleSearch,
      location,
      ...toRefs(state)
    };
  },
});
